/// <reference path="./global.d.ts" />
import {
    addErrorHandler,
    getMountedApps,
    navigateToUrl,
    registerApplication,
    start,
} from 'single-spa';

import manifest from '../public/manifest.json';
import { authHandler } from './auth/AuthManger';

interface AppConfig {
    activeWhen?: string;
    activeRegex?: string;
    hideShell?: boolean;
}

const APOLLO_SHELL_ID = 'apollo-shell';

function setApShellAttribute(attribute: string, value: string) {
    const shell = document.getElementById(APOLLO_SHELL_ID);
    if (shell) {
        shell.setAttribute(attribute, value);
    } else {
        console.error(`Unable to find ap-shell element to set attribute ${attribute} with value ${value}`);
    }
}

function setOverrides() {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;

    if (searchParams.get('spa-reset')) {
        (window as any).importMapOverrides.resetOverrides();
        localStorage.removeItem('devtools');
    }

    searchParams.getAll('spa-module').forEach(module => {
        const { name, path } = JSON.parse(module);

        (window as any).importMapOverrides.addOverride(name, path);
        localStorage.setItem('devtools', 'true');
    });
}

function setRootContainer() {
    const org = window.location.pathname.split('/')[1];
    const tenant = window.location.pathname.split('/')[2];

    const hasOrg = org && !org.endsWith('_');
    const hasTenant = hasOrg && tenant && !tenant.endsWith('_');

    (window as any).__ROOT_CONTAINER__ = {
        portalDomain: `${PORTAL_DOMAIN}`,
        org: hasOrg ? org : undefined,
        tenant: hasTenant ? tenant : undefined,
    };

    const shell = document.createElement('ap-shell');

    shell.id = APOLLO_SHELL_ID;
    shell.setAttribute('hideShell', 'true');

    document.querySelector('main').appendChild(shell);
}
setRootContainer();

function initializeShell() {
    const org = window.location.pathname.split('/')[1];

    // prevent initialize shell if no org name exists
    if (!org || org.endsWith('_')) {
        return;
    }

    (window as any).PortalShell.initialize({
        devProperties: {
            organizationStandardUrl: `${PORTAL_ORIGIN}`,
            organizationName: org.endsWith('_') ? undefined : org,
            mode: 'dev-cloud',
            isMFERoot: true,
        },
    });
}

if ((window as any).PortalShell) {
    initializeShell();
} else {
    document.addEventListener('portalShellLoaded', () => {
        initializeShell();
    });
}

Object.entries(manifest).forEach(([appName, config]) => {
    const { hideShell, activeRegex, activeWhen } = config as AppConfig;

    registerApplication({
        name: appName,
        app: () => {
            setApShellAttribute('hide-shell', hideShell ? 'true' : 'false');

            return import(/* webpackIgnore: true */ appName).then(module => {
                if (module.get) {
                    // this is a module federation app, get the container factory and call it
                    return module.get('./Module').then(m => m());
                }
                return module
            })
        },
        activeWhen: [
            (location) => {
                if (activeRegex) {
                    return !!location.pathname.toLowerCase().match(activeRegex);
                }

                return false;
            },
            ...(activeWhen?.split(',') ?? []),
        ],
        customProps: { domElement: document.getElementById(APOLLO_SHELL_ID) },
    });
});

window.addEventListener('single-spa:before-routing-event', (evt) => {
    document.getElementById('single-spa-error-container').innerHTML = ``;
});

window.addEventListener('single-spa:routing-event', (evt) => {
    // This is to handle the initial default page transition to portal_ service.
    // This is needed when we don't have an org or a service_ in the url.
    if (getMountedApps().length === 0) {
        const location = window.location;
        const parts = location.pathname.split('/').filter(Boolean);
        if (parts.length < 2) {
            navigateToUrl('/portal_');
        }
    }
});

document.addEventListener('serviceClicked', (event: CustomEvent<{ serviceid: string, url: string }>) => {
    setApShellAttribute('active', event.detail.serviceid);
    navigateToUrl(event.detail.url);
});

addErrorHandler((err) => {
    console.error('Error during single-spa lifecycle:', err);
    document.getElementById('single-spa-error-container').innerHTML = `<h1 style="color: red;">Application Error</h1><p>Error during single-spa lifecycle: ${err.message}</p>`;
});

const startApp = (hasUser?: boolean) => {
    if (hasUser) {
        (window as any).__IS_AUTHENTICATED__ = true;
    }

    // At this point, we are authenticated and can start the app
    document.body.style.display = 'unset';
    start({ urlRerouteOnly: true });
};

// set import map overrides for PR preview
setOverrides();

// Main entry point
// Core responsibility is to handle authentication and start the app
authHandler(startApp);