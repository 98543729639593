/// <reference path="../global.d.ts" />
import { navigateToUrl } from 'single-spa';
import createAuth0Client from '@auth0/auth0-spa-js';
import {
    IdentityUserManager,
    IdentityUserManagerSettings,
    setUserManager,
} from '@uipath/auth-core';

export const authHandler = (startApp: (hasUser: boolean) => void) => {
    const identityConfig = (orgName: string) => ({
        authority: `${PORTAL_ORIGIN}/identity_`,
        redirect_uri: `${window.location.origin}/${orgName}/portal_/loginsuccess`,
        silent_redirect_uri: `${window.location.origin}/portal_/loginsuccess`,
        post_logout_redirect_uri: `${window.location.origin}/portal_/cloudrpa`,
        acr_values: `tenantName:${orgName}`,
        // usePortalSilentRedirectUri: true,
    });

    const auth0Config = createAuth0Client({
        domain: `${AUTH0_DOMAIN}`,
        client_id: `${PORTAL_GLOBAL_APPLICATION_CLIENT_ID}`,
        cacheLocation: 'localstorage',
        redirect_uri: `${window.location.origin}/portal_/authCallback`,
        audience: `${PORTAL_GLOBAL_APPLICATION_AUDIENCE}`,
        scope: 'openid profile email read:current_user update:current_user_metadata',
    });

    const redirectKey = 'uipath.root.redirect-to';
    const im = new IdentityUserManager(identityConfig(window.location.pathname.split('/')[1]) as IdentityUserManagerSettings)

    // handle identity success login
    if (window.sessionStorage.getItem(redirectKey) && location.pathname.endsWith('/portal_/loginsuccess')) {
        // This code path is for the case where the user is redirected back to the app after logging in
        // Since other apps may do there own login and redirect, we specifically look for are redirect key
        im.signinCallback().then((user) => {
            if (user) {
                const redirectTo = window.sessionStorage.getItem(redirectKey);
                window.sessionStorage.removeItem(redirectKey);
                // sets the user manager for auth0 library
                setUserManager(im);
                startApp(true);
                navigateToUrl(redirectTo, { replace: true });
            }
        });

        return;
    }

    // handle auth0 redirect scenario
    if (location.pathname.includes('/portal_/authCallback')) {
        auth0Config.then((auth0) => {
            auth0.handleRedirectCallback().then((user) => {
                user && startApp(true);
                user?.appState?.targetUrl && navigateToUrl(user.appState.targetUrl);
            });
        });

        return;
    }

    // perform auth0 login (since no org name is present in the url)
    if (location.pathname.startsWith('/portal_') || location.pathname === '/') {
        auth0Config.then((auth0) => {
            auth0.getUser().then((user) => {
                if (!user) {
                    auth0.loginWithRedirect({ appState: { targetUrl: window.location.pathname } });

                    return;
                }

                // Global app still needs to perform identity auth
                startApp(false);
            });
        })

        return;
    }

    // handle default scenario
    im.getUser().then((user) => {
        // if no user, perform identity auth
        if (!user) {
            let path = window.location.pathname + window.location.search + window.location.hash;

            if (!window.location.pathname.endsWith('_')) {
                path += path.endsWith('/') ? 'portal_' : '/portal_';
            }

            window.sessionStorage.setItem(redirectKey, path);
            // pass in the org name parsed from the current url to acr_values
            im.signinRedirect();
            return;
        }

        // otherwise, set the user manager and start the app
        setUserManager(im);
        startApp(true);
    });
}